// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_kw";
export var caseStudyBackground__lineColor = "bz_kt";
export var caseStudyCta__bgColor = "bz_lG";
export var caseStudyHead__imageWrapper = "bz_kp";
export var caseStudyProjectsSection = "bz_kx";
export var caseStudyQuote__bgDark = "bz_lH";
export var caseStudyQuote__bgLight = "bz_kD";
export var caseStudyQuote__bgRing = "bz_kr";
export var caseStudySolution__ring = "bz_kB";
export var caseStudySolution__ringThree = "bz_lJ";
export var caseStudySolution__ringTwo = "bz_kC";
export var caseStudyVideo = "bz_kS";
export var caseStudyVideo__ring = "bz_kG";
export var caseStudy__bgDark = "bz_km";
export var caseStudy__bgLight = "bz_kl";